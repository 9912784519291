import React from "react"
import styled from "styled-components"
import pattern from "../images/pattern.jpg"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import Heading from "./heading"

const TextWithImage = ({ section, headingShadow, headingDowncase, even }) => {
  return (
    <Wrapper>
      <CustomContainer even={even}>
        {section.image && (
          <img src={section.image.url} alt={section.image.alt} />
        )}
        <Content>
          {section.title && (
            <Heading as="h3" shadow={headingShadow} downcase={headingDowncase}>
              {section.title}
            </Heading>
          )}
          <div dangerouslySetInnerHTML={{ __html: section.text }} />
        </Content>
      </CustomContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${DesignTokens.spacing[5]};
  img,
  h3,
  p {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 769px) {
    padding: 3rem 0;
    background-size: 50%;
    img {
      max-width: 50%;
    }
  }
`

const CustomContainer = styled(Container)`
  background: ${DesignTokens.colors.black};
  background-image: url(${pattern});
  background-size: 200%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 769px) {
    /* align-items: center; */
    background-size: 50%;
    flex-direction: ${props => (props.even ? "row-reverse" : "row")};
    img {
      margin-bottom: 0;
      margin-right: ${DesignTokens.spacing[9]};
    }
  }
`

const Content = styled.div`
  margin-top: 2rem;
  img {
    max-width: 100%;
  }
`

export default TextWithImage
